<template>
  <div class="layout_index layout_index_404">
    <div class="page_404">
      <h1 class="page_404__title">{{ "404_title" | localize }}</h1>
      <p class="page_404__text">{{ "404_text" | localize }}</p>
      <router-link to="/" class="button button_404">{{ "go_to_main" | localize }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page_not_found'
}
</script>
